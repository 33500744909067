const toggleFn = () => {
    const $toggles = document.querySelectorAll('.js-toggle');
    if($toggles.length === 0) return;
    $toggles.forEach($toggle => {
        const $target = document.getElementById($toggle.dataset.target);

        if($target) {
            $toggle.addEventListener('click', () => {
                console.log($target);
                $target.classList.toggle('collapse');
            });
        }
    });
}
export default toggleFn;
