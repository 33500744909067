const comebackFn = () => {
    const $comeback = document.querySelector('.js-comeback');
    if ($comeback) {
        $comeback.addEventListener('click', () => {
            console.log('comeback');
            window.history.back();
        });
    }
}
export default comebackFn;
