import rangeFn from "./range";
import toggleFn from "./toggler";
import comeback from "./comeback";
import buttonGroup from "./button_group";

document.addEventListener('DOMContentLoaded', () => {
    rangeFn();
    toggleFn();
    comeback();
    buttonGroup();
});
