const buttonGroup = () => {
    const $buttons = document.querySelectorAll('.btn-group button');
    const $buttonStart = document.getElementById('button-start');
    if (!$buttons.length || !$buttonStart) return;
    $buttons.forEach($button => {
            $button.addEventListener('click', () => {
                if ($buttonStart.classList.contains('disabled')) {
                    $buttonStart.classList.remove('disabled');
                }
                $buttons.forEach($btn => {
                    $btn.classList.remove('active');
                });
                $button.classList.add('active');
            });
        }
    );
}
export default buttonGroup
