const rangeFn = () => {
    const $ranges = document.querySelectorAll('.js-range-slider');
    const $total = document.getElementById('total');
    const $totalContainer = document.getElementById('total-container');
    if ($ranges.length && $total) {
        const getTotal = () => {
            let total = 0;
            $ranges.forEach($range => {
                total += parseInt($range.value, 10);
            });
            return total;
        };

        const resetTotal = () => {
            const total = getTotal();
            $total.innerHTML = total;
            if (total !== 100) {
                $totalContainer.classList.remove('border-success');
                $totalContainer.classList.remove('text-success');
                $totalContainer.classList.add('border-danger');
                $totalContainer.classList.add('text-danger');
            } else {
                $totalContainer.classList.remove('border-danger');
                $totalContainer.classList.remove('text-danger');
                $totalContainer.classList.add('border-success');
                $totalContainer.classList.add('text-success');
            }
        }
        resetTotal();

        $ranges.forEach($range => {
            const id = $range.dataset.range;
            const $text = document.getElementById(`percent_${id}`);
            let currentValue = $range.value;
            if ($text) {
                $range.addEventListener('input', (event) => {
                    const total = getTotal();
                    if (total <= 100) {
                        currentValue = event.target.value;
                        $text.innerHTML = currentValue;
                        resetTotal();
                    } else {
                        event.target.value = currentValue;
                        event.preventDefault();
                        $text.innerHTML = currentValue;
                        return false;
                    }

                });
            }
        });
    }
};

export default rangeFn;
